
import type { SwiperOptions } from "swiper"
import indexSwiperMixin from "@/mixins/index-swiper"
import type { NewsItem } from "@/types/api/news"
import { getBeforeAfter } from "@/utils/before-after"
import breakpoints from "@/assets/style/_breakpoints.scss"

export default indexSwiperMixin.extend({
  props: {
    beforeYear: { type: Number, default: undefined },
    afterYear: { type: Number, default: undefined },
  },

  data: () => ({
    videos: [] as NewsItem[],
  }),

  async fetch () {
    const [res, err] = await this.$api.news.getNews({
      params: {
        "paginator[limit]": 12,
        "filter[type]": "video",
        ...this.beforeAfter,
      },
    })
    if (err) console.error(err)
    else this.videos = this.$api.helpers.getList(res)
  },

  computed: {
    beforeAfter (): Partial<Record<string, number>> {
      return getBeforeAfter(this.beforeYear, this.afterYear)
    },

    swiperOptions (): SwiperOptions {
      return {
        spaceBetween: 15,
        slidesPerView: "auto",
        navigation: {
          prevEl: `#${this.prevId}`,
          nextEl: `#${this.nextId}`,
          disabledClass: "index-swiper-nav-disabled",
        },
        breakpoints: {
          [breakpoints.xs]: {
            spaceBetween: 20,
          },
        },
      }
    },
  },
})
