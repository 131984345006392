
import Vue from "vue"

export default Vue.extend({
  layoutSettings: {
    fullWidth: true,
  },

  data: () => ({
    afterYear: 2024,
  }),
})
