import { render, staticRenderFns } from "./index.vue?vue&type=template&id=741d4603"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=741d4603&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IndexHero: require('/app/components/index/IndexHero.vue').default,AppVideo: require('/app/components/app/AppVideo.vue').default,IndexEvents: require('/app/components/index/IndexEvents.vue').default,IndexOnlineEvents: require('/app/components/index/IndexOnlineEvents.vue').default,IndexInventorNew: require('/app/components/index/IndexInventorNew.vue').default,IndexNews: require('/app/components/index/IndexNews.vue').default,IndexVideos: require('/app/components/index/IndexVideos.vue').default,IndexPress: require('/app/components/index/IndexPress.vue').default,IndexNumbers: require('/app/components/index/IndexNumbers.vue').default,IndexPartnersNew: require('/app/components/index/IndexPartnersNew.vue').default})
