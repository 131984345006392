
import Vue, { toRaw } from "vue"

export default Vue.extend({
  data: () => ({
    organizers: toRaw([
      "voir",
      "minobr",
      "desyatiletie",
    ]),
  }),
})
