// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8qVc16Uj{background-color:#f5f6ff;border-radius:max(1.25rem,min(2.08vw,2.5rem));display:flex;flex-wrap:wrap;gap:max(2rem,min(2.08vw,2.5rem));justify-content:space-between;padding:max(1.25rem,min(2.08vw,2.5rem))}@media(max-width:667.98px){._8qVc16Uj{margin:0 calc(var(--p-x)*-1)}}.QhaZi7lJ{flex-basis:32.5rem;flex-grow:1}.QhaZi7lJ,.lHBHYUZt{display:flex;flex-direction:column;gap:1rem;justify-content:space-between}.Gh\\+H26Q5{color:#434343;font-size:max(.88rem,min(1.04vw,1.25rem));font-weight:600;letter-spacing:.09em;line-height:1.2}.Gh\\+H26Q5,._1h5DMB\\+Q{text-transform:uppercase}._1h5DMB\\+Q{font-size:max(1.5rem,min(2.08vw,2.5rem));font-weight:400;line-height:1}.Ui5Zo-Xd{color:#434343;font-size:.88rem;font-weight:500;line-height:1.6;max-width:32.5rem}.dF5KSC\\+4{flex-basis:max(0rem,min(27.08vw,32.5rem));flex-grow:1}.wz8tsIEt{border-radius:1.5rem;height:100%;-o-object-fit:cover;object-fit:cover;-o-object-position:right;object-position:right;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"xl": "1920.98",
	"container": "_8qVc16Uj",
	"containerStart": "QhaZi7lJ",
	"containerStartStart": "lHBHYUZt",
	"subtitle": "Gh+H26Q5",
	"title": "_1h5DMB+Q",
	"text": "Ui5Zo-Xd",
	"imgWrapper": "dF5KSC+4",
	"img": "wz8tsIEt"
};
module.exports = ___CSS_LOADER_EXPORT___;
