// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iEkpM7r2>*+*{margin-top:max(1.5rem,min(3.33vw,4rem))}.N\\+sSSujW .swiper-container{--x:var(--p-x);--y:calc((100vw - var(--content-width))/2);padding:0 max(var(--x),var(--y))}.igzLL4kq{height:auto!important;padding:2px;width:max(20.94rem,min(32.71vw,39.25rem))!important}.igzLL4kq>*{height:100%}.VwjnZ200{background:#f5f6ff;border-radius:1.25rem;display:block;transition:.2s ease-in-out;transition-property:transform}.VwjnZ200:active{transform:scale(.95)}.B82EeRQ\\+{border-radius:inherit;height:max(13rem,min(17.86vw,21.44rem));-o-object-fit:cover;object-fit:cover;width:100%}._7Re7HjUd{padding:max(1rem,min(1.25vw,1.5rem))}._6VTZBUzJ{display:flex;font-size:.88rem;font-weight:500;line-height:1.43;margin-bottom:max(1rem,min(.52vw,.63rem));opacity:.5;white-space:nowrap}._6VTZBUzJ>*+*{margin-left:max(.84rem,min(1.25vw,1.5rem))}.fvuFRV6Q{overflow:hidden;text-overflow:ellipsis}._7kT6xe3c{color:var(--color-gray-4);font-size:max(1rem,min(1.04vw,1.25rem));font-weight:600;line-height:1.2;-webkit-line-clamp:3}.RQDWMMb4,._7kT6xe3c{word-wrap:break-word;display:-webkit-box;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis}.RQDWMMb4{font-size:1rem;line-height:1.4;padding-top:max(1rem,min(.52vw,.63rem));-webkit-line-clamp:5}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"xl": "1920.98",
	"container": "iEkpM7r2",
	"swiper": "N+sSSujW",
	"swiperSlide": "igzLL4kq",
	"video": "VwjnZ200",
	"videoCover": "B82EeRQ+",
	"videoInner": "_7Re7HjUd",
	"videoHeader": "_6VTZBUzJ",
	"videoTag": "fvuFRV6Q",
	"videoTitle": "_7kT6xe3c",
	"videoText": "RQDWMMb4"
};
module.exports = ___CSS_LOADER_EXPORT___;
