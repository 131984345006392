
import { defineComponent } from "vue"
import type { SwiperOptions } from "swiper"
import type { ProgramEvent } from "@/types/api/program"
import indexSwiperMixin from "@/mixins/index-swiper"
import { getBeforeAfter } from "@/utils/before-after"

export default defineComponent({
  mixins: [indexSwiperMixin],

  props: {
    beforeYear: { type: Number, default: undefined },
    afterYear: { type: Number, default: undefined },
  },

  data: () => ({
    events: [] as ProgramEvent[],
  }),

  async fetch () {
    const [res, err] = await this.$api.program.getEvents({
      params: {
        "filter[type]": "online",
        "paginator[limit]": 1000,
        ...this.beforeAfter,
      },
    })
    if (err) console.error(err)
    else this.events = this.$api.helpers.getList(res)
  },

  computed: {
    beforeAfter (): Partial<Record<string, number>> {
      return getBeforeAfter(this.beforeYear, this.afterYear)
    },

    swiperOptions (): SwiperOptions {
      return {
        spaceBetween: 20,
        slidesPerView: "auto",
        navigation: {
          prevEl: `#${this.prevId}`,
          nextEl: `#${this.nextId}`,
          disabledClass: "index-swiper-nav-disabled",
        },
        autoplay: true,
      }
    },
  },

  methods: {
    getCover (event: ProgramEvent): string {
      return event.frontImage?.url || event.image?.url || ""
    },
  },
})
