// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kkPBuXf4>*+*{margin-top:max(1.5rem,min(2.5vw,3rem))}.Xpept5ti .swiper-container{--x:var(--p-x);--y:calc((100vw - var(--content-width))/2);padding:0 max(var(--x),var(--y))}._6iFk7CXF{height:6.25rem!important;padding:2px;width:max(15.63rem,min(15.63vw,18.75rem))!important}.NVeKNzc5{align-items:center;display:flex;height:100%;justify-content:center;width:100%}._01PftY-x{height:80%;-o-object-fit:contain;object-fit:contain;width:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"xl": "1920.98",
	"container": "kkPBuXf4",
	"swiper": "Xpept5ti",
	"swiperSlide": "_6iFk7CXF",
	"partner": "NVeKNzc5",
	"partnerLogo": "_01PftY-x"
};
module.exports = ___CSS_LOADER_EXPORT___;
