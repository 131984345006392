// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tqAXz2dh>*+*{margin-top:max(4rem,min(4.17vw,5rem))}@media(max-width:667.98px){.tqAXz2dh .IHJLXw34{margin:0 calc(var(--p-x)*-1)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"xl": "1920.98",
	"container": "tqAXz2dh",
	"video": "IHJLXw34"
};
module.exports = ___CSS_LOADER_EXPORT___;
