
import { defineComponent, toRaw } from "vue"

interface Card {
  count: string
  title: string
  text?: string
}

export default defineComponent({
  data: () => ({
    cardsStart: toRaw([
      {
        count: "16",
        title: "Городов",
        text: "Тюмень, Донецк, Рязань, Екатеринбург, Новосибирск, Владивосток, Омск, Казань, Калининград, Великий Новгород, Ульяновск, Элиста, Абакан, Москва, Сочи, Московская область",
      },
      {
        count: "11",
        title: "онлайн-мероприятий",
      },
      {
        count: "50",
        title: "мероприятий",
        text: "форумы, конференции, выставки, мастер-классы, научные стендапы, семинары, лекции, онлайн-курсы",
      },
      {
        count: "350 000",
        title: "участников по&nbsp;всей стране",
      },
    ] as Card[]),

    cardsEnd: toRaw([
      {
        count: "850",
        title: "новых изобретений и&nbsp;разработок",
      },
      {
        count: "7500",
        title: "публикаций в&nbsp;СМИ и&nbsp;соцсетях о&nbsp;ВОИРФЕСТе",
      },
      {
        count: "3 млн",
        title: "просмотров в&nbsp;соцсетях",
      },
      {
        count: "90 млн",
        title: "аудитория в&nbsp;соцсетях",
      },
      {
        count: "25 млн",
        title: "охват СМИ",
      },
    ] as Card[]),
  }),
})
