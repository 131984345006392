
import type { SwiperOptions } from "swiper"
import indexSwiperMixin from "@/mixins/index-swiper"
import type { ProgramEvent } from "@/types/api/program"
import { getBeforeAfter } from "@/utils/before-after"

export type ProgramEventWithDate =
  Omit<ProgramEvent, "start"> & { start: NonNullable<ProgramEvent["start"]> }

export default indexSwiperMixin.extend({
  props: {
    subheading: { type: String, default: "Что вас ждет" },
    heading: { type: String, default: "Календарь мероприятий" },
    beforeYear: { type: Number, default: undefined },
    afterYear: { type: Number, default: undefined },
  },

  data: () => ({
    events: [] as ProgramEventWithDate[],
  }),

  async fetch () {
    const [res, err] = await this.$api.program.getEvents({
      params: {
        "filter[type]": "event",
        "paginator[limit]": 12,
        ...this.beforeAfter,
      },
    })
    if (err) {
      console.error(err)
      return
    }

    const allEvents: ProgramEvent[] = this.$api.helpers.getList(res)

    // const allEventsReversed = [...allEvents].reverse()
    // const todayTs = Date.now()

    this.events = allEvents.filter((e): e is ProgramEventWithDate => {
      if (!e.start) return false
      return true
      // return e.start >= todayTs
    })
  },

  computed: {
    beforeAfter (): Partial<Record<string, number>> {
      return getBeforeAfter(this.beforeYear, this.afterYear)
    },

    swiperOptions (): SwiperOptions {
      return {
        spaceBetween: 20,
        slidesPerView: "auto",
        navigation: {
          prevEl: `#${this.prevId}`,
          nextEl: `#${this.nextId}`,
          disabledClass: "index-swiper-nav-disabled",
        },
      }
    },
  },
})
